import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    background: ${colors.backgroundGlass};
    border: 1px solid ${colors.backgroundDarkGrey};
    box-sizing: border-box;
    backdrop-filter: blur(1.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    /* Mobile screens */
    padding: 16px;
    margin: 0px 24px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 500px;
        min-height: 288px;
        margin: 0;
        padding: 23px 27px;
    }
`

const BoxNumber = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.secondary};
    margin: 0px;
    text-decoration-line: underline;

    /* Mobile screens */
    font-size: ${sizes.xs};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.small};
        margin: 4px 0px;
    }
`

const Header = styled.div`
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-weight: 500;
    color: ${colors.primary};

    /* Mobile screens */
    font-size: ${sizes.base};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.xxl};
        margin: 4px 0px;
    }
`

const Text = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 0px;

    /* Mobile screens */
    font-size: ${sizes.small};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
        margin: 4px 0px;
    }
`

interface Props {
    id: React.ReactNode
    header: React.ReactNode
    text: React.ReactNode
}

const Component = ({ id, header, text }: Props) => {
    return (
        <Card>
            <BoxNumber>{id}</BoxNumber>
            <Header>{header}</Header>
            <Text>{text}</Text>
        </Card>
    )
}

export default Component
