import * as React from 'react'
import styled from 'styled-components'
import { colors, sizes, fonts, mediaQuery } from '../assets/styles/styleguide'
import RarityCard from '../components/cards/RarityCard'
import { ReactComponent as TopologySvg } from '../assets/images/topology-warped.svg'

const Topology = styled(TopologySvg)`
    position: absolute;
    transform: rotate(90deg);
    opacity: 0.35;
    > path {
        stroke: ${colors.popColors.red};
    }

    /* Mobile screens */
    width: 1199.6px;
    height: 1220px;
    right: -880px;
    top: -80px;
    transform: rotate(-12deg);

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 1776px;
        height: 1806.19px;
        right: -1080px;
        top: -200px;
        transform: rotate(0deg);
    }
`

const Body = styled.div`
    position: relative;
    width: 100%;
    top: 0px;
    margin-top: 40px;
`

const Title = styled.div`
    position: relative;
    font-family: ${fonts.fontFamilyHeader};
    color: ${colors.primary};
    font-weight: bold;

    /* Mobile screens */
    font-size: ${sizes.base};
    margin: 20px 12px 0px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.title};
        margin: 80px 98px 0px;
    }
`

const Text = styled.div`
    display: inline-block;
    position: relative;
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: regular;
    color: ${colors.primary};

    /* Mobile screens */
    font-size: ${sizes.small};
    margin: 20px 12px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 60%;
        font-size: ${sizes.large};
        margin: 20px 98px 0px;
    }
`

const CardHolder = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    /* Mobile screens */
    flex-direction: column;
    margin: 26px 0 0;
    gap: 16px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        flex-direction: row;
        margin: 46px 0 0;
    }
`

type Props = {
    id: string
}

const Page = ({ id }: Props) => {
    return (
        <Body id={id}>
            <Topology />
            <Title>Minting</Title>
            <Text>
                When Cubes are minted they are automatically popped for the
                first time. Popping a Cube for the first time sets its base
                color permanently. Unlike normal pop colors which occur with
                equal chance, first-time pop colors occur with different
                rarities.
            </Text>
            <CardHolder>
                <RarityCard />
            </CardHolder>
        </Body>
    )
}
export default Page
