import * as React from 'react'
import styled from 'styled-components'
import { colors, sizes, fonts, mediaQuery } from '../assets/styles/styleguide'
import LogoButton from '../components/buttons/LogoButton'
import Link from 'react-anchor-link-smooth-scroll'

const Body = styled.div`
    position: relative;
    min-height: 139px;
    width: 100%;
    margin: 140px 0 20px;
`

const Box = styled.div`
    width: 90%;
    display: flex;
    margin: 0 auto;
    padding: 20px;
    align-items: flex-start;
    border-top: 1px solid ${colors.backgroundDarkGrey};

    /* Mobile screens */
    flex-direction: column;
    gap: 12px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        flex-direction: row;
        width: 85%;
    }
`

const Organization = styled.div`
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    /* Mobile screens */
    width: 100%;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 50%;
        align-items: flex-start;
    }
`

const Chunk = styled.div`
    /* Mobile screens */
    width: 100%;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 50%;
    }
`

const Row = styled.div`
    display: flex;
    width: 100%;
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: light;
    color: ${colors.primary};
`

const StyledA = styled.a`
    width: 100%;
    font-family: ${fonts.fontFamily};
    font-weight: light;
    color: ${colors.primary};
    text-decoration: none;
    text-align: left;

    /* Mobile screens */
    font-size: ${sizes.small};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
        text-align: right;
    }
`

const Scroll = styled(Link)`
    width: 100%;
    font-family: ${fonts.fontFamily};
    font-weight: light;
    color: ${colors.accentGold};
    text-decoration: none;
    text-align: left;

    /* Mobile screens */
    font-size: ${sizes.small};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
        text-align: right;
    }
`

type Props = {}

const Page = (props: Props) => {
    return (
        <Body>
            <Box>
                <Organization>
                    <LogoButton />
                </Organization>
                <Chunk>
                    <Row>
                        <StyledA
                            href="https://discord.gg/aHvTXCSxpN"
                            target="_blank"
                        >
                            Cube Community
                        </StyledA>
                    </Row>
                    <Row>
                        <StyledA
                            href="https://twitter.com/projectcubeio/"
                            target="_blank"
                        >
                            Latest Announcements
                        </StyledA>
                    </Row>
                    <Row>
                        <StyledA
                            href="https://www.instagram.com/projectcube.io/"
                            target="_blank"
                        >
                            Sneak Previews
                        </StyledA>
                    </Row>
                    <Row>
                        <Scroll href="#top">Back to top</Scroll>
                    </Row>
                </Chunk>
            </Box>
        </Body>
    )
}
export default Page
