import { useState } from 'react'
import { useRef } from 'react'
import styled from 'styled-components'
import { colors, mediaQuery } from '../../assets/styles/styleguide'
import LogoButton from '../buttons/LogoButton'
import NavButton, { AltNavButton } from '../buttons/NavButton'
import IconButton from '../buttons/IconButton'
import { ReactComponent as InstagramSvg } from '../../assets/images/instagram.svg'
import { ReactComponent as TwitterSvg } from '../../assets/images/twitter.svg'
import { ReactComponent as DiscordSvg } from '../../assets/images/discord.svg'
import Burger from '../buttons/Burger'
import MobileMenu from '../menu/MobileMenu'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const Body = styled.div`
    position: absolute;
    border-radius: 24px;
    z-index: 1;
    background: ${colors.backgroundOverlay};

    /* Inside Auto Layout */
    display: flex;
    align-items: center;
    color: ${colors.primary};
    height: 84px;

    /* Mobile-first */
    top: 18px;
    width: calc(100% - 72px);
    margin: 0px 12px;
    padding: 0px 24px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        top: 22px;
        width: calc(100% - 144px);
        margin: 0px 48px;
        justify-content: space-between;
    }
`

const OtherButtons = styled.div`
    display: none;
    width: 100%;
    justify-content: flex-end;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: flex;
    }
`

const BurgerBar = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: none;
    }
`

const MobileBurger = styled(Burger)`
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: none;
    }
`

const NavBar = () => {
    const [open, setOpen] = useState<boolean>(false)
    const node = useRef<HTMLDivElement>(null)
    useOnClickOutside(node, () => setOpen(false))
    return (
        <Body>
            <LogoButton />
            <OtherButtons>
                <AltNavButton
                    text="world"
                    link={`https://world.projectcube.io`}
                />
                <NavButton text="about" link={`#about`} />
                <NavButton text="features" link={`#features`} />
                <NavButton text="minting" link={`#minting`} />
                <NavButton text="roadmap" link={`#roadmap`} />
                <IconButton link="https://www.instagram.com/projectcube.io/">
                    <InstagramSvg />
                </IconButton>
                <IconButton link="https://twitter.com/projectcubeio/">
                    <TwitterSvg />
                </IconButton>
                <IconButton link="https://discord.gg/aHvTXCSxpN">
                    <DiscordSvg />
                </IconButton>
            </OtherButtons>
            <BurgerBar ref={node}>
                <MobileBurger open={open} setOpen={setOpen} />
                <MobileMenu open={open} setOpen={setOpen} />
            </BurgerBar>
        </Body>
    )
}

export default NavBar
