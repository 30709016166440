interface Coordinates {
    x: number
    y: number
    z: number
}

export const coordOfId = (id: number, cubeSide: number): Coordinates => {
    let offsetId = id - 1
    const x = (offsetId % cubeSide) + 1
    const y = Math.floor((offsetId / cubeSide) % cubeSide) + 1
    const z = (Math.floor(offsetId / cubeSide ** 2) % cubeSide) + 1
    return { x, y, z }
}

export const stringOfCoord = (coords: Coordinates) => {
    return `(${coords.x}, ${coords.y}, ${coords.z})`
}

export const idOfCoord = (coord: Coordinates, cubeSide: number): number => {
    return coord.x + (coord.y - 1) * cubeSide + (coord.z - 1) * cubeSide ** 2
}

export const truncateAccount = (acc: string): string => {
    const len = acc.length
    if (len < 12) {
        return acc
    }
    const start = acc.substring(0, 6)
    const end = acc.substring(len - 4, len)
    return `${start}...${end}`
}

export const darkenHexColorString = (hex: string, offset: number) => {
    const hexNoHash = hex.substring(1)
    const hexInInt = parseInt(hexNoHash, 16) - offset
    if (hexInInt < 0) {
        return `#000000`
    }
    return `#${hexInInt.toString(16)}`
}
