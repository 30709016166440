export interface Cube {
    id: number
    owner: string
    buyoutPrice: number
    popPrice: number
    popColor: string
    popExpiry: number
    expired: boolean
}

export const DummyCubeWithId = (id: number): Cube => {
    return {
        id,
        owner: 'dummy123456789',
        buyoutPrice: 0,
        popPrice: 0,
        popColor: 'grey',
        popExpiry: 0,
        expired: false,
    }
}
