import { colors, mediaQuery } from '../../assets/styles/styleguide'
import styled from 'styled-components'
import { bool } from 'prop-types'
import NavButton, { AltNavButton } from '../buttons/NavButton'
import IconButton from '../buttons/IconButton'
import { ReactComponent as InstagramSvg } from '../../assets/images/instagram.svg'
import { ReactComponent as TwitterSvg } from '../../assets/images/twitter.svg'
import { ReactComponent as DiscordSvg } from '../../assets/images/discord.svg'

const StyledMenu = styled.nav<{ open: boolean }>`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 22px;
    background: ${colors.backgroundOverlay};
    outline: 20px solid ${colors.backgroundOverlay};
    border: 1px solid ${colors.backgroundOverlay};
    text-align: right;
    padding: 80px 20px 12px 60px;
    position: absolute;
    border-radius: 8px;
    top: 0;
    right: 0;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    // transform: translateX(-100%);
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        display: none;
    }
`
interface Props {
    open: any
    setOpen: any
}

const Menu = ({ open, setOpen }: Props) => {
    return (
        <StyledMenu open={open}>
            <AltNavButton
                text="world"
                link={`https://world.projectcube.io`}
                handler={() => setOpen(!open)}
            />
            <NavButton
                text="about"
                link={`#about`}
                handler={() => setOpen(!open)}
            />
            <NavButton
                text="features"
                link={`#features`}
                handler={() => setOpen(!open)}
            />
            <NavButton
                text="minting"
                link={`#minting`}
                handler={() => setOpen(!open)}
            />
            <NavButton
                text="roadmap"
                link={`#roadmap`}
                handler={() => setOpen(!open)}
            />
            <IconButton link="https://www.instagram.com/projectcube.io/">
                <InstagramSvg />
            </IconButton>
            <IconButton link="https://twitter.com/projectcubeio/">
                <TwitterSvg />
            </IconButton>
            <IconButton link="https://discord.gg/aHvTXCSxpN">
                <DiscordSvg />
            </IconButton>
        </StyledMenu>
    )
}
Menu.propTypes = {
    open: bool.isRequired,
}

export default Menu
