import styled from 'styled-components'
import Link from 'react-anchor-link-smooth-scroll'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import LogoUrl from '../../assets/images/logo.png'

const Logo = styled.div`
    height: 48px;
    width: 48px;
    background: url(${LogoUrl});
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 100%;
`

const StyledBox = styled(Link)`
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
    border: none;
    height: 36px;
    text-decoration: none;

    background: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`

const TextBox = styled.div`
    display: inline-block;
    position: relative;
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-size: ${sizes.base};
    font-weight: regular;
    color: ${colors.primary};
    text-align: center;
    line-height: 150%;
`

const Component = () => {
    return (
        <StyledBox href="#top">
            <Logo />
            <TextBox>projectcube</TextBox>
        </StyledBox>
    )
}

export default Component
