import styled from 'styled-components'
import Link from 'react-anchor-link-smooth-scroll'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import { ReactComponent as LogoSvg } from '../../assets/images/top.svg'

const Logo = styled(LogoSvg)`
    height: 24px;
    width: 24px;
    > path {
        fill: ${colors.accentGold};
    }
`

const StyledBox = styled(Link)`
    position: fixed;
    bottom: 24px;
    right: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 62px;
    height: 62px;
    background: ${colors.backgroundOverlay};
    border-radius: 14px;
    gap: 2px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    @media (hover: hover) {
        :hover {
            border: 1px solid ${colors.accentGold};
        }
    }
`

const TextBox = styled.div`
    display: inline-block;
    position: relative;
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-size: ${sizes.xs};
    font-weight: regular;
    color: ${colors.accentGold};
    text-align: center;
    line-height: 150%;
`

const Component = () => {
    return (
        <StyledBox href="#top">
            <Logo />
            <TextBox>top</TextBox>
        </StyledBox>
    )
}

export default Component
