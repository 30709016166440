import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'
import diamondRed from '../../assets/images/diamond-red.png'
import diamondOrange from '../../assets/images/diamond-orange.png'
import diamondYellow from '../../assets/images/diamond-yellow.png'
import diamondGreen from '../../assets/images/diamond-green.png'
import diamondBlue from '../../assets/images/diamond-blue.png'
import diamondIndigo from '../../assets/images/diamond-indigo.png'
import diamondPink from '../../assets/images/diamond-pink.png'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 14px;

    background: ${colors.backgroundOverlay};
    border: 1px solid ${colors.backgroundDarkGrey};
    box-sizing: border-box;
    border-radius: 8px;

    /* Mobile screens */
    width: 275px;
    height: 360px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 275px;
        height: 360px;
    }
`

const Header = styled.div`
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-weight: bold;
    color: ${colors.primary};
    font-size: ${sizes.base};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
`

const ImgToDiamond = (imgUrl: string) => {
    return styled.div`
        height: 24px;
        width: 24px;
        margin: 0px 10px;
        background: url(${imgUrl});
        background-size: contain;
        background-repeat: no-repeat;
        background-size: 100%;
    `
}

const DiamondRed = ImgToDiamond(diamondRed)
const DiamondOrange = ImgToDiamond(diamondOrange)
const DiamondYellow = ImgToDiamond(diamondYellow)
const DiamondGreen = ImgToDiamond(diamondGreen)
const DiamondBlue = ImgToDiamond(diamondBlue)
const DiamondIndigo = ImgToDiamond(diamondIndigo)
const DiamondPink = ImgToDiamond(diamondPink)

const Color = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 0 10px;
    font-size: ${sizes.large};
`

const Percentage = styled.div`
    flex-grow: 1;
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 0 10px;
    font-size: ${sizes.large};
    text-align: right;
`

interface Props {}

const Component = (props: Props) => {
    return (
        <Card>
            <Header>Rarity Info</Header>
            <Row>
                <DiamondRed />
                <Color>Red</Color>
                <Percentage>22%</Percentage>
            </Row>
            <Row>
                <DiamondOrange />
                <Color>Orange</Color>
                <Percentage>22%</Percentage>
            </Row>
            <Row>
                <DiamondYellow />
                <Color>Yellow</Color>
                <Percentage>22%</Percentage>
            </Row>
            <Row>
                <DiamondGreen />
                <Color>Green</Color>
                <Percentage>22%</Percentage>
            </Row>
            <Row>
                <DiamondBlue />
                <Color>Blue</Color>
                <Percentage>6%</Percentage>
            </Row>
            <Row>
                <DiamondIndigo />
                <Color>Indigo</Color>
                <Percentage>4%</Percentage>
            </Row>
            <Row>
                <DiamondPink />
                <Color>Pink</Color>
                <Percentage>2%</Percentage>
            </Row>
        </Card>
    )
}

export default Component
