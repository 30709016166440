import styled from 'styled-components'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'
import Link from 'react-anchor-link-smooth-scroll'

const StyledBox = styled(Link)`
    display: flex;
    align-items: center;
    height: 39px;
    padding: 0px 12px;

    border: 1px solid ${colors.accentGold};
    box-sizing: border-box;
    border-radius: 12px;
    background: ${colors.background};

    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: normal;
    font-size: ${sizes.small};
    color: ${colors.accentGold};
    text-align: center;
    line-height: 150%;
    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    @media (hover: hover) {
        :hover {
            background: ${colors.accentGold};
            color: ${colors.background};
            border: 1px solid ${colors.accentGold};
        }
    }
`

type ComponentProps = {
    text: string
    link: string
}

const Component = ({ text, link }: ComponentProps) => {
    return <StyledBox href={link}>{text}</StyledBox>
}

export default Component
