import * as THREE from 'three'
import React, { useRef, useState, useEffect } from 'react'
import {
    ReactThreeFiber,
    Canvas,
    extend,
    useThree,
    useFrame,
} from '@react-three/fiber'
import { colors } from '../assets/styles/styleguide'
import { Cube, DummyCubeWithId } from '../cube'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { coordOfId } from '../util/helpers'

extend({ EffectComposer, RenderPass, UnrealBloomPass })

declare global {
    namespace JSX {
        interface IntrinsicElements {
            effectComposer: ReactThreeFiber.Object3DNode<
                EffectComposer,
                typeof EffectComposer
            >
            renderPass: ReactThreeFiber.Object3DNode<
                RenderPass,
                typeof RenderPass
            >
            unrealBloomPass: ReactThreeFiber.Object3DNode<
                UnrealBloomPass,
                typeof UnrealBloomPass
            >
            orbitControls: ReactThreeFiber.Object3DNode<
                OrbitControls,
                typeof OrbitControls
            >
        }
    }
}

extend({ OrbitControls })

const CameraControls = () => {
    const {
        camera,
        gl: { domElement },
    } = useThree()
    const controls = useRef<OrbitControls>()
    useFrame((state) => controls.current?.update())
    return (
        <orbitControls
            target={new THREE.Vector3(0, -3, 0)}
            ref={controls}
            enablePan={false}
            autoRotate={true}
            autoRotateSpeed={1.5}
            rotateSpeed={0.2}
            enableZoom={false}
            minDistance={5}
            maxDistance={5}
            args={[camera, domElement]}
        />
    )
}

interface BoxProps {
    key: number
    position: THREE.Vector3
    cube: Cube
    opacity: number
    transparent: boolean
}

const Box = ({ cube, opacity, transparent, ...props }: BoxProps) => {
    const color = cube.popColor ? cube.popColor : 'grey'
    return (
        <mesh {...props} scale={1}>
            <boxGeometry args={[0.7, 0.7, 0.7]} />
            <meshStandardMaterial
                roughness={1}
                color={color}
                opacity={opacity}
                transparent={transparent}
            />
        </mesh>
    )
}

interface BoxesProps {
    cubeList: Array<Cube>
    cubesPerSide: number
}

const PoppedBoxes = ({ cubeList, cubesPerSide }: BoxesProps) => {
    const offset = (cubesPerSide + 1) / 2
    return (
        <>
            {cubeList.map((cube, index) => {
                const { x, y, z } = coordOfId(cube.id, cubesPerSide)
                return (
                    <Box
                        key={index}
                        position={
                            new THREE.Vector3(
                                x - offset,
                                y - offset - 3,
                                z - offset,
                            )
                        }
                        cube={cube}
                        opacity={1}
                        transparent={false}
                    />
                )
            })}
        </>
    )
}

const UnpoppedBoxes = ({ cubeList, cubesPerSide }: BoxesProps) => {
    const offset = (cubesPerSide + 1) / 2
    return (
        <>
            {cubeList.map((cube, index) => {
                const { x, y, z } = coordOfId(cube.id, cubesPerSide)
                return (
                    <Box
                        key={index}
                        position={
                            new THREE.Vector3(
                                x - offset,
                                y - offset - 3,
                                z - offset,
                            )
                        }
                        cube={cube}
                        opacity={0.2}
                        transparent={true}
                    />
                )
            })}
        </>
    )
}

interface PropsWithChildren {
    children: React.ReactNode
}

function Bloom({ children }: PropsWithChildren) {
    const { gl, camera, size } = useThree()
    const [scene, setScene] = useState<THREE.Scene>()
    const composer = useRef<EffectComposer>()
    useEffect(
        () => scene && composer.current!.setSize(size.width, size.height),
        [size, scene],
    )
    useFrame(() => scene && composer.current!.render(), 1)
    return (
        <>
            <scene ref={setScene}>{children}</scene>
            <effectComposer ref={composer} args={[gl]}>
                <renderPass
                    attachArray="passes"
                    scene={scene}
                    camera={camera}
                />
                <unrealBloomPass
                    attachArray="passes"
                    args={[
                        new THREE.Vector2(size.width, size.height),
                        1.2,
                        1,
                        0,
                    ]}
                />
            </effectComposer>
        </>
    )
}

function Main({ children }: PropsWithChildren) {
    const scene = useRef<THREE.Scene>()
    const { gl, camera } = useThree()
    useFrame(() => {
        gl.autoClear = false
        gl.clearDepth()
        gl.render(scene.current!, camera)
    }, 2)
    return <scene ref={scene}>{children}</scene>
}

const MyCanvas = () => {
    const cubes = []
    const poppedCubes = []
    const popColors = Object.values(colors.popColors)
    for (var i = 1; i <= 27; i++) {
        if (Math.random() > 0.2) {
            cubes.push(DummyCubeWithId(i))
        } else {
            const popped = DummyCubeWithId(i)
            poppedCubes.push(popped)
            const id = Math.floor(Math.random() * 10) % 7
            popped.popColor = popColors[id]
        }
    }
    return (
        <Canvas>
            <CameraControls />
            <Main>
                <pointLight />
                <ambientLight />
                <UnpoppedBoxes cubeList={cubes} cubesPerSide={3} />
                <Bloom>
                    <ambientLight />
                    <PoppedBoxes cubeList={poppedCubes} cubesPerSide={3} />
                </Bloom>
            </Main>
        </Canvas>
    )
}

export default MyCanvas
