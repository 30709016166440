import * as React from 'react'
import styled from 'styled-components'
import { colors, sizes, fonts, mediaQuery } from '../assets/styles/styleguide'
import FeatureCard from '../components/cards/FeatureCard'
import { ReactComponent as RareSvg } from '../assets/images/rare.svg'
import { ReactComponent as InteractiveSvg } from '../assets/images/interactive.svg'
import { ReactComponent as TradableSvg } from '../assets/images/tradable.svg'
import { ReactComponent as LeasableSvg } from '../assets/images/leasable.svg'

const Body = styled.div`
    position: relative;
    width: 100%;
    top: 0px;
    margin-top: 40px;
`

const Title = styled.div`
    position: relative;
    font-family: ${fonts.fontFamilyHeader};
    color: ${colors.primary};
    font-weight: bold;

    /* Mobile screens */
    font-size: ${sizes.base};
    margin: 20px 12px 0px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.title};
        margin: 80px 98px 0px;
    }
`

const CardHolder = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    /* Mobile screens */
    flex-direction: column;
    margin: 40px 12px 0;
    gap: 16px;

    /* Desktop screens */
    @media only screen and (min-width: 1353px) {
        flex-direction: row;
        padding: 37px 24px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
`

type Props = {
    id: string
}

const Page = ({ id }: Props) => {
    return (
        <Body id={id}>
            <Title>Features</Title>
            <CardHolder>
                <Row>
                    <FeatureCard
                        icon={RareSvg}
                        header="Rare"
                        text={
                            <>
                                Limited to 1000 cubes forever. The Project Cube
                                Collection is the first functional NFT, and the
                                rarest.
                            </>
                        }
                    />
                    <FeatureCard
                        icon={InteractiveSvg}
                        header="Interactive"
                        text={
                            <>
                                Every Cube can be popped by its owner, changing
                                to a random color for a few days before fading
                                back to its base color.
                            </>
                        }
                    />
                </Row>
                <Row>
                    <FeatureCard
                        icon={TradableSvg}
                        header="Tradable"
                        text={
                            <>
                                Buying and selling Cubes is directly built-in,
                                no marketplace needed. Skip the third party
                                fees.
                            </>
                        }
                    />
                    <FeatureCard
                        icon={LeasableSvg}
                        header="Leasable"
                        text={
                            <>
                                Rent your Cube out and earn passive income. Let
                                anyone in the world pop your Cube for whatever
                                price you want.
                            </>
                        }
                    />
                </Row>
            </CardHolder>
        </Body>
    )
}
export default Page
