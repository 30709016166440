import * as React from 'react'
import styled from 'styled-components'
import { colors, sizes, fonts, mediaQuery } from '../assets/styles/styleguide'
import RoadmapCard from '../components/cards/RoadmapCard'
import { ReactComponent as TopologySvg } from '../assets/images/topology-jagged.svg'

const Topology = styled(TopologySvg)`
    position: absolute;
    transform: rotate(90deg);
    opacity: 0.35;
    > path {
        stroke: ${colors.popColors.green};
    }
    transform: rotate(-5deg);

    /* Mobile screens */
    width: 712.85px;
    height: 762.06px;
    left: -619px;
    top: 227.13px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 1054px;
        height: 1126.76px;
        left: -740px;
        top: 400px;
    }
`

const Body = styled.div`
    position: relative;
    width: 100%;
    top: 0px;
    margin-top: 40px;
`

const Title = styled.div`
    position: relative;
    font-family: ${fonts.fontFamilyHeader};
    color: ${colors.primary};
    font-weight: bold;

    /* Mobile screens */
    font-size: ${sizes.base};
    margin: 20px 12px 0px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.title};
        margin: 80px 98px 0px;
    }
`

const CardHolder = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    /* Mobile screens */
    flex-direction: column;
    margin: 40px 0 0;
    gap: 30px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        padding: 37px 24px;
        gap: 60px;
    }
`

const A = styled.a`
    color: ${colors.accent};
    visited {
        color: ${colors.accent};
    }
`

type Props = {
    id: string
}

const Page = ({ id }: Props) => {
    return (
        <Body id={id}>
            <Topology />
            <Title>Roadmap</Title>
            <CardHolder>
                <RoadmapCard
                    header="Beta"
                    percentage={
                        <span style={{ color: colors.accentGreen }}>
                            Ended May 20th
                        </span>
                    }
                    text={
                        <>
                            Closed beta testing of{' '}
                            <A href="http://beta.projectcube.io/">Cube World</A>
                            .
                        </>
                    }
                />
                <RoadmapCard
                    header="Launch"
                    percentage={
                        <span>
                            <s>May 31st</s> Jun 10th
                        </span>
                    }
                    text={
                        <>
                            Launch of Cube World and deployment of the NFT
                            contract. Delayed.
                        </>
                    }
                />
                <RoadmapCard
                    header="Phase 2"
                    percentage="Q2 2022"
                    text={
                        <>
                            Release of the in-built XSGD exchange contract,
                            leasing mechanisms, and feeless trading.
                        </>
                    }
                />
            </CardHolder>
        </Body>
    )
}
export default Page
