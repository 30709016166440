import React from 'react'
import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background: none;

    /* Mobile screens */
    padding: 0;
    margin: 0;
    width: 180px;
    gap: 16px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 284px;
        margin: 0px 14px;
        padding: 0;
        gap: 4px;
    }
`

const Iconify = (icon: any) => {
    return styled(icon)`
        margin: 0 auto;

        /* Mobile screens */
        height: 76px;
        max-width: 108px;

        /* Desktop screens */
        @media only screen and (min-width: ${mediaQuery.laptopMin}) {
            height: 180px;
            max-width: 150px;
        }
    `
}

const Header = styled.div`
    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-weight: 500;
    line-height: 54px;
    color: ${colors.accentGold};
    text-align: center;

    /* Mobile screens */
    font-size: ${sizes.large};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-weight: bold;
        font-size: ${sizes.xxxl};
        line-height: 52px;
    }
`

const Text = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 0px;
    text-align: center;

    /* Mobile screens */
    font-size: ${sizes.xs};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
    }
`

interface Props {
    icon: any
    header: React.ReactNode
    text: React.ReactNode
}

const Component = ({ icon, header, text }: Props) => {
    const Icon = Iconify(icon)
    return (
        <Card>
            <Icon />
            <Header>{header}</Header>
            <Text>{text}</Text>
        </Card>
    )
}

export default Component
