import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'
import Link from 'react-anchor-link-smooth-scroll'

const StyledBox = styled(Link)`
    display: flex;
    align-items: center;
    height: 36px;
    gap: 8px;
    padding: 0px 12px;
    border: none;

    color: ${colors.primary};
    font-size: ${sizes.base};
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: right;

    &:hover {
        color: ${colors.accentGold};
    }

    background: ${colors.backgroundOverlay};
    font-family: ${fonts.fontFamily};
    font-style: normal;

    font-weight: regular;
    text-align: center;
    line-height: 150%;
    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
        color: ${colors.accentGold};
    }
`

const TextBox = styled.div<{ color?: string }>`
    display: inline-block;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: ${(props) =>
            props.color ? props.color : colors.accentGold};
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }
    :hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
`

type ComponentProps = {
    text: string
    link: string
    handler?: () => void
}

const Component = ({ text, link, handler }: ComponentProps) => {
    return (
        <StyledBox href={link} onClick={() => handler && handler()}>
            <TextBox>{text}</TextBox>
        </StyledBox>
    )
}

export default Component

const AltNav = styled.a`
    display: flex;
    align-items: center;
    height: 36px;
    gap: 8px;
    padding: 0px 12px;
    border: none;

    background: none;
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-size: ${sizes.base};
    font-weight: regular;
    color: ${colors.accentGreen};
    text-align: center;
    line-height: 150%;
    text-decoration: none;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
    }
`

export const AltNavButton = ({ text, link, handler }: ComponentProps) => {
    return (
        <AltNav href={link} onClick={() => handler && handler()}>
            <TextBox color={`${colors.accentGreen}`}>{text}</TextBox>
        </AltNav>
    )
}
