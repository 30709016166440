import styled from 'styled-components'
import {
    colors,
    sizes,
    fonts,
    mediaQuery,
} from '../../assets/styles/styleguide'

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 4px;

    background: ${colors.backgroundGlass};
    border: 1px solid ${colors.backgroundDarkGrey};
    box-sizing: border-box;
    backdrop-filter: blur(1.5px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    /* Mobile screens */
    width: calc(100% - 48px);
    max-width: 459px;
    padding: 20px;
    margin: 0px 24px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 459px;
        min-height: 100px;
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const Percentage = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.popColors.orange};
    margin: 0px;
    flex-grow: 1;
    text-align: right;

    /* Mobile screens */
    font-size: ${sizes.xs};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.base};
    }
`

const Header = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    font-weight: 500;
    color: ${colors.accentGold};

    /* Mobile screens */
    font-size: ${sizes.base};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.base};
    }
`

const Text = styled.div`
    font-family: ${fonts.fontFamily};
    font-style: normal;
    color: ${colors.primary};
    margin: 0px;

    /* Mobile screens */
    font-size: ${sizes.small};

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        font-size: ${sizes.regular};
    }
`

interface Props {
    header: React.ReactNode
    percentage: React.ReactNode
    text: React.ReactNode
}

const Component = ({ header, percentage, text }: Props) => {
    return (
        <Card>
            <Row>
                <Header>{header}</Header>
                <Percentage>{percentage}</Percentage>
            </Row>
            <Text>{text}</Text>
        </Card>
    )
}

export default Component
