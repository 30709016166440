import * as React from 'react'
import styled from 'styled-components'
import { colors, mediaQuery } from '../assets/styles/styleguide'
import InfoCard from '../components/cards/InfoCard'
import { ReactComponent as TopologySvg } from '../assets/images/topology-round.svg'

const TopologyLeft = styled(TopologySvg)`
    position: absolute;
    transform: rotate(90deg);
    opacity: 0.35;
    > path {
        stroke: ${colors.popColors.orange};
    }

    /* Mobile screens */
    width: 570.15px;
    height: 548px;
    left: -400px;
    top: 28px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 969px;
        height: 946.36px;
        left: -600px;
        top: 60px;
    }
`

const TopologyRight = styled(TopologySvg)`
    position: absolute;
    transform: rotate(-90deg);
    opacity: 0.35;
    > path {
        stroke: ${colors.popColors.yellow};
    }

    /* Mobile screens */
    width: 588.92px;
    height: 566.33px;
    right: -340px;
    top: 500px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 835px;
        height: 815.49px;
        right: -360px;
        top: 516px;
    }
`

const Body = styled.div`
    position: relative;
    width: 100%;
    top: 0px;
    margin-top: 40px;
    overflow: visible;

    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        min-height: 1080px;
    }
`

const CardHolder = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Mobile screens */
    margin-top: 45px;
    gap: 20px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        gap: 42px;
        margin-top: 122px;
    }
`

const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 840px;
    }
`

const RowRightAlign = styled(Row)`
    position: relative;
    display: flex;
    flex-direction: row-reverse;
`

const A = styled.a`
    color: ${colors.accent};
    visited {
        color: ${colors.accent};
    }
`

type Props = {
    id: string
}

const Page = ({ id }: Props) => {
    return (
        <Body id={id}>
            <TopologyLeft />
            <TopologyRight />
            <CardHolder>
                <Row>
                    <InfoCard
                        id="01"
                        header={
                            <>
                                100%{' '}
                                <span style={{ color: `${colors.accentGold}` }}>
                                    On-Chain
                                </span>
                            </>
                        }
                        text={
                            <>
                                Every byte of data for Project Cube is stored
                                on-chain. Yes, that includes even the functional
                                stuff. For you as an owner, that means your Cube
                                will never face link rot, or be subject to the
                                whims of a developer gone mad.
                            </>
                        }
                    />
                </Row>
                <RowRightAlign>
                    <InfoCard
                        id="02"
                        header={
                            <>
                                <span style={{ color: `${colors.accentGold}` }}>
                                    Built-in{' '}
                                </span>
                                XSGD Exchange
                            </>
                        }
                        text={
                            <>
                                Every Cube is minted, sold, bought, and rented
                                with XSGD, a native stable-coin pegged to the
                                Singapore Dollar and approved by the{' '}
                                <A
                                    href={'https://www.straitsx.com/sg/xsgd'}
                                    style={{
                                        textDecoration: 'none',
                                        color: `${colors.highlight} !important`,
                                    }}
                                >
                                    Monetary Authority of Singapore
                                </A>
                                . That means even in times of crypto market
                                volatility, your Cube's price holds strong. All
                                the upside, none of the volatility.
                            </>
                        }
                    />
                </RowRightAlign>
                <Row>
                    <InfoCard
                        id="03"
                        header={
                            <>
                                Functionally{' '}
                                <span style={{ color: `${colors.accentGold}` }}>
                                    Unique
                                </span>
                            </>
                        }
                        text={
                            <>
                                Every Cube can be popped to change its color
                                temporarily. Let others pop your Cube for any
                                fee you want, or keep it all to yourself. It's
                                up to you as an owner. Whatever the case, Cubes
                                are unreplicable by design, unlike other NFT
                                artwork that can be copied pixel-for-pixel. Go
                                ahead, try to screenshot one.
                            </>
                        }
                    />
                </Row>
            </CardHolder>
        </Body>
    )
}
export default Page
