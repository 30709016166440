import React from 'react'
import styled from 'styled-components'
import { colors, fonts, mediaQuery } from '../assets/styles/styleguide'
import NavBar from '../components/navbar/NavBar'
import BasicButton from '../components/buttons/BasicButton'
import ExternalLinkButton from '../components/buttons/ExternalLinkButton'
import TopButton from '../components/buttons/TopButton'
import Canvas from './Canvas'
import About from './About'
import Features from './Features'
import Minting from './Minting'
import Roadmap from './Roadmap'
import Footer from './Footer'
import { Grid } from '@mui/material'

const Body = styled.div`
    position: static;
    width: 100%;
    overflow: hidden;
    background: ${colors.background};
    display: flex;
    flex-direction: column;
    margin: 0;
`

const MainPage = styled.div`
    min-height: 640px;
    height: 100vh;
    width: 100%;
    background: ${colors.background};
    margin: 0;
    overflow: hidden;
`

const CanvasDiv = styled.div`
    min-height: 580px;
    height: 80vh;
    width: 100%;
    background: ${colors.background};
    margin: 0;
`

const HeaderBox = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

    /* Mobile screens */
    bottom: 80px;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        top: 68vh;
        height: 200px;
    }
`

const HeadingText = styled.div`
    position: relative;
    margin: 0 auto;

    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: ${colors.secondary};

    /* Mobile screens */
    font-size: 44px;
    line-height: 44px;
    width: 96%;

    -webkit-font-smoothing: subpixel-antialiased;
    -webkit-text-stroke: 1px transparent;

    /* Desktop screens */
    @media only screen and (min-width: ${mediaQuery.laptopMin}) {
        width: 1080px;
        font-size: 72px;
        line-height: 72px;
    }
`

const MainComponent = () => {
    return (
        <>
            <Body id="top">
                <MainPage>
                    <NavBar />
                    <CanvasDiv>
                        <Canvas />
                        <HeaderBox>
                            <HeadingText>
                                Defining the next generation of{' '}
                                <span
                                    style={{
                                        color: `${colors.primary}`,
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    On-Chain
                                </span>{' '}
                                NFTs
                            </HeadingText>
                            <Grid
                                container
                                gap={2}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <BasicButton text="Learn More" link="#about" />
                                <ExternalLinkButton
                                    text="Jump in Now"
                                    link="https://world.projectcube.io/"
                                />
                            </Grid>
                        </HeaderBox>
                    </CanvasDiv>
                </MainPage>
                <About id="about" />
                <Features id="features" />
                <Minting id="minting" />
                <Roadmap id="roadmap" />
                <Footer />
            </Body>
            <TopButton />
        </>
    )
}

export default MainComponent
