import styled from 'styled-components'
import { colors, sizes, fonts } from '../../assets/styles/styleguide'

const StyledBox = styled.a`
    display: flex;
    align-items: center;
    white-space: nowrap;
    height: 36px;
    gap: 8px;
    padding: 0px 12px;
    border: none;

    background: ${colors.backgroundOverlay};

    font-family: ${fonts.fontFamilyHeader};
    font-style: normal;
    font-size: ${sizes.base};
    font-weight: regular;
    color: ${colors.primary};
    text-align: center;
    line-height: 150%;
`
interface Props {
    link: string
    children: React.ReactNode
}

const Component = ({ link, children }: Props) => {
    return (
        <StyledBox href={link} target="_blank">
            {children}
        </StyledBox>
    )
}

export default Component
